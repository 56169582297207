import { Pipe, PipeTransform } from '@angular/core';
import {timeUnits} from '../../../constants/shared/time-units';

@Pipe({
  name: 'getDateDifference',
})
export class GetDateDifferencePipe implements PipeTransform {
  transform(date: string, ...args: any[]): string | null {
    const NO_DATE = new Date('0001-01-01T00:00:00').getTime();
    const startDate = new Date(date);
    const endDate = new Date();
    const diffInSecs = (endDate.getTime() - startDate.getTime()) / timeUnits.SECOND;

    if (diffInSecs < timeUnits.MINUTE) { return Math.abs(Math.floor(diffInSecs)) + ' secs ago'; }
    if (diffInSecs < timeUnits.HOUR) { return Math.abs(Math.floor(diffInSecs / timeUnits.MINUTE)) + ' mins ago'; }
    if (diffInSecs < timeUnits.DAY) { return Math.abs(Math.floor(diffInSecs / timeUnits.HOUR)) + ' hours ago'; }
    if (diffInSecs < timeUnits.WEEK) { return Math.abs(Math.floor(diffInSecs / timeUnits.DAY)) + ' days ago'; }
    if (startDate.getTime() === NO_DATE) { return 'Never'; }

    return null;
  }
}
