import {Component, OnInit} from '@angular/core';
import {fullImageUrl, ProductImageService} from '../../product-image.service';
import {Store} from '@ngrx/store';
import * as productsImagesActions from '../../../../store/products/actions/product-image.actions';
import {IImage} from '../../../../interfaces/products/image';
import {IProduct} from '../../../../interfaces/products/product';

@Component({
  selector: 'app-product-photo',
  templateUrl: './product-photo-modal.component.html',
  styleUrls: ['./product-photo-modal.component.css']
})

export class ProductPhotoModalComponent implements OnInit {
  currentPhoto: IImage;
  currentPhotoIndex: number;
  photoList: IImage[];
  productId: number;
  fullImageUrl = fullImageUrl;

  constructor(private store: Store<{products: {currentProduct: IProduct}}>, public productImagesService: ProductImageService) { }

  ngOnInit() {
    this.currentPhotoIndex = this.photoList.map(i => i.id).indexOf(this.currentPhoto.id);
  }


  previousClick() {
    const currentIndex = this.photoList.map(i => i.id).indexOf(this.currentPhoto.id);
    this.currentPhotoIndex = currentIndex === 0 ? this.photoList.length - 1 : currentIndex - 1;
    this.currentPhoto = this.photoList[this.currentPhotoIndex];
  }

  nextClick() {
    const currentIndex = this.photoList.map(i => i.id).indexOf(this.currentPhoto.id);
    this.currentPhotoIndex = currentIndex === (this.photoList.length - 1) ? 0 : currentIndex + 1;
    this.currentPhoto = this.photoList[this.currentPhotoIndex];
  }

  setAsPrimaryClick() {
    this.store.dispatch(productsImagesActions.setPrimaryImage({productId: this.productId, fileEntityId: this.currentPhoto.id}));
    this.currentPhoto = {...this.currentPhoto, isPrimary: true};
  }

  deleteImgClick() {
    this.store.dispatch(productsImagesActions.deleteImage({productId: this.productId, fileEntityId: this.currentPhoto.id}));
    this.nextClick();
  }

}
