import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  getClientMessage(error: Error): string {
    if (!navigator.onLine) {
      return 'No Internet Connection';
    }

    return error?.message || error.toString();
  }

  getServerMessage(error: HttpErrorResponse): string {
    return error.status === 503
      ? '503 - Server Temporarily Unavailable'
      : (error.error?.message || error.message);
  }
}
