import { Pipe, PipeTransform } from '@angular/core';
import { INavItem } from '../../../interfaces/shared/nav-items';
import { AuthService } from '../../../services/auth.service';

@Pipe({
  name: 'restrictAccess'
})
export class RestrictAccessPipe implements PipeTransform {

  constructor(private authService: AuthService) {}

  transform(values: INavItem[], ...args: any[]): INavItem[] {
    const currentRole = this.authService.getUserRole();
    const menuItemsDisplayed = values.filter(value => (!value.protect || value.protect.includes(currentRole)));
    return menuItemsDisplayed;
  }
}
