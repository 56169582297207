import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import 'devextreme/integration/jquery';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']

})
export class AppComponent {
  constructor(public authService: AuthService) { }

  isMobileMenu() {
    return window.innerWidth < 991;
  }
}
