import {BehaviorSubject, Observable} from 'rxjs';
import {map, take, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {ILoginServerRes} from '../interfaces/auth/login-server-response';
import {environment} from '../../environments/environment';
import {ILoginData} from '../interfaces/auth/login';
import {AuthHubService, authMethods} from './auth-hub.service';
import {ITokensData} from '../interfaces/auth/tokens-data';
import {ERoles} from '../interfaces/identity/roles-enum';

@Injectable({providedIn: 'root'})

export class AuthService {
  private readonly adminApi = environment.adminApiPath;
  private readonly TOKEN = 'TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private readonly ROLE = 'ROLE';
  private loginUserToken = new BehaviorSubject<string>(localStorage.getItem(this.TOKEN));

  public isLogin(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.loginUserToken.asObservable()
        .subscribe((data) => data ? resolve(true) : resolve(false)).unsubscribe();
    });
  }

  constructor(private router: Router, private api: HttpClient, private authHubService: AuthHubService) {
    this.authHubService.getAuthActions().subscribe(data => {
      switch (data?.method) {
        case authMethods.refreshTokens:
          this.refreshTokens().subscribe();
          break;

        case authMethods.removeTokens:
          this.removeTokens();
          break;

        case authMethods.removeUserRole:
          this.removeUserRole();
          break;

        default:
          break;
      }
    });
  }

  login(data: ILoginData): Observable<ILoginServerRes> {
    return this.api.post<ILoginServerRes>(`${this.adminApi}login`, data)
      .pipe(
        tap((res: ILoginServerRes) => {
          this.loginUserToken.next(res.tokensData.token);
          this.authHubService.connect(res.tokensData.token);
        })
      );
  }

  refreshTokens(): Observable<ITokensData> {
    return this.api.post<ITokensData>(`${this.adminApi}refresh`,
      {token: this.getToken(), refreshToken: this.getRefreshToken()})
      .pipe(
        tap((res: ITokensData) => {
          this.authHubService.connect(res.token);
          this.addTokensToStorage(res.token, res.refreshToken);
        }));
  }

  navigateAfterSuccessfulLogin() {
    this.router.navigateByUrl('/home');
  }

  isLoggedIn() {
    return !!localStorage.getItem(this.TOKEN) && !!localStorage.getItem(this.REFRESH_TOKEN);
  }

  getToken() {
    return localStorage.getItem(this.TOKEN);
  }

  getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  getUserRole() {
    const currentRole = localStorage.getItem(this.ROLE);
    return ERoles[currentRole];
  }

  addTokensToStorage(token: string, refreshToken: string) {
    localStorage.setItem(this.TOKEN, token);
    localStorage.setItem(this.REFRESH_TOKEN, refreshToken);
  }

  addUserRoleToStorage(role: ERoles) {
    localStorage.setItem(this.ROLE, role);
  }

  restrictTo(rolesAllowed: ERoles | ERoles[]): boolean {
    const currentRole = this.getUserRole();
    return Array.isArray(rolesAllowed) ? rolesAllowed.includes(currentRole) : rolesAllowed === currentRole;
  }

  logout(): Observable<string> {
    this.authHubService.forceLogout();
    return this.api.post<void>(`${this.adminApi}logout`, {}).pipe(
      map(res => {
        this.clientLogout();
        return 'ok';
      }));
  }

  clientLogout(shouldReload = false) {
    this.removeTokens();
    this.removeUserRole();
    this.router.navigateByUrl(`/login`);
    if (shouldReload) {
      window.location.reload();
    }
  }

  getActiveSessionsCount(): Observable<{}> {
    return this.api.get(`${this.adminApi}activetokenscount`).pipe(take(1));
  }

  private removeUserRole() {
    localStorage.removeItem(this.ROLE);
  }

  private removeTokens() {
    localStorage.removeItem(this.TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
  }
}

