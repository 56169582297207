<div class="container modal-container">
    <div class="modal-header justify-content-center">
      <h3 class="p-0 m-0 modal-title">This order cannot be edited.</h3>
    </div>
    <div>
      <div class="d-flex justify-content-center">
        <dx-button
          text="OK"
          type="success"
          class="ok-button"
          aria-label="Close"
          (onClick)="bsModalRef.hide()"
          [useSubmitBehavior]="false"
        >
        </dx-button>
      </div>
    </div>
  </div>

