<div class="photo-modal">
  <button [disabled]="photoList.length <= 1"
          (click)="previousClick()" class="btn btn-sm btn-outline-info bg-white nav-button"> <i class="material-icons">arrow_left</i></button>

  <div class="img-container">
    <img [src]="fullImageUrl + currentPhoto.id" (error)="$event.target.src = productImagesService.noImageApi" alt="photo {{currentPhoto.id}}" class="photo"/>
    <p>id: {{currentPhoto.id}}</p>
    <p>{{currentPhotoIndex + 1}} of {{photoList.length}}</p>

    <dx-button
      type="default"
      [disabled]="currentPhoto.isPrimary"
      stylingMode="outlined"
      text="set as Primary"
      [width]="'600px'"
      (onClick)="setAsPrimaryClick()">
    </dx-button>

    <dx-button
      type="danger"
      stylingMode="text"
      [disabled]="false"
      text="delete"
      (onClick)="deleteImgClick()">
    </dx-button>
  </div>

  <button [disabled]="photoList.length <= 1"
          (click)="nextClick()" class="btn btn-sm btn-outline-info bg-white nav-button"> <i class="material-icons">arrow_right</i> </button>
</div>

