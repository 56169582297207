import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {AuthGuard} from './services/auth.guard';
import {ENavItem} from './interfaces/shared/nav-items-enum';
import { ERoles } from './interfaces/identity/roles-enum';

const appRoutes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: ENavItem.products},
  {path: ENavItem.login, loadChildren: () => import('./components/auth/auth.module').then(m => m.AuthModule)},
  {path: ENavItem.home, loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard]},
  {path: ENavItem.products, loadChildren: () => import('./components/products/products.module').then(m => m.ProductsModule), canActivate: [AuthGuard]},
  {path: ENavItem.productsKit, loadChildren: () => import('./components/products-kit/products-kit.module').then(m => m.ProductsKitModule), canActivate: [AuthGuard]},
  {path: ENavItem.orders, loadChildren: () => import('./components/orders/orders.module').then(m => m.OrdersModule), canActivate: [AuthGuard]},
  {path: ENavItem.customers, loadChildren: () => import('./components/customers/customers.module').then(m => m.CustomersModule), canActivate: [AuthGuard]},
  {path: ENavItem.admin, loadChildren: () => import('./components/administration/administration.module').then(m => m.AdministrationModule), canActivate: [AuthGuard]},
  {path: ENavItem.adminUsers, loadChildren: () => import('./components/admins-management/admins-management.module').then(m => m.AdminsManagementModule), canActivate: [AuthGuard], data: {roles: [ERoles.SuperAdmin]}},
  {path: ENavItem.bonuses, loadChildren: () => import('./components/bonuses-and-discounts/bonuses-and-discounts.module').then(m => m.BonusesAndDiscountsModule), canActivate: [AuthGuard]},
  {path: ENavItem.trips, loadChildren: () => import('./components/trips-and-events/trips-and-events.module').then(m => m.TripsAndEventsModule), canActivate: [AuthGuard]},
  {path: '**', component: NotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class RoutingModule { }

