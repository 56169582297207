import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { INameId } from '../interfaces/shared/name-and-id';
import { ICategory } from '../interfaces/shared/category';

@Injectable({
  providedIn: 'root'
})

export class GlobalDataService {
  private readonly adminApi = environment.adminApiPath;
  categories: Array<ICategory> = [];
  countries: Array<INameId> = [];

  constructor(private api: HttpClient, private authService: AuthService) { }

  fetchCategories(): Observable<ICategory[]> {
    return this.api.get<ICategory[]>(`${this.adminApi}categories`);
  }

  fetchCountries(): Observable<INameId[]> {
    return this.api.get<INameId[]>(`${this.adminApi}countries`);
  }

  fetchAndSaveInitData() {
    this.fetchCategories()
      .subscribe((res: ICategory[]) => this.categories = res);
    this.fetchCountries()
      .subscribe((res: INameId[]) => this.countries = res);
  }

  public initCountries(): Promise<void> {
    if (!this.authService.isLoggedIn()) {
      return;
    }

    return this.fetchCountries()
      .toPromise()
      .then((result: Array<INameId>) => {
        this.countries = result;
      });
  }

  public initCategories(): Promise<void> {
    if (!this.authService.isLoggedIn()) {
      return;
    }

    return this.fetchCategories()
      .toPromise()
      .then((result: Array<INameId>) => {
        this.categories = result;
      });
  }

}

export function userServiceFactoryForCountries(provider: GlobalDataService): () => Promise<void> {
  return () => provider.initCountries();
}

export function userServiceFactoryForCategories(provider: GlobalDataService): () => Promise<void> {
  return () => provider.initCategories();
}
