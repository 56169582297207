import { BehaviorSubject, Observable } from 'rxjs';
import * as signalR from '@microsoft/signalr';
import { Injectable } from '@angular/core';

export enum authMethods {
  refreshTokens = 'refreshTokens',
  removeTokens = 'removeTokens',
  removeUserRole = 'removeUserRole'
}

@Injectable({
  providedIn: 'root'
})
export class AuthHubService {
  private hubConnection: signalR.HubConnection;
  private readonly TOKEN = 'TOKEN';
  private authActions: BehaviorSubject<{ method: authMethods }> = new BehaviorSubject(null);
  logOut: BehaviorSubject<{ thisClient: boolean, status: boolean }> = new BehaviorSubject({ thisClient: false, status: false });

  constructor() { }

  connect(token = localStorage.getItem(this.TOKEN)): void {
    if (!token) {
      return;
    }
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl('/auth', {
        transport: signalR.HttpTransportType.LongPolling,
        accessTokenFactory: () => token
      })
      .configureLogging(signalR.LogLevel.Critical)
      .build();

    this.hubConnection
      .start()
      .catch(err => console.log('Error while starting hub connection: ' + err));

      this.hubConnection.onclose((err: Error & { statusCode: number }) => {
      if (err.statusCode === 401 && localStorage.getItem(this.TOKEN)) {
        this.authActions.next({ method: authMethods.refreshTokens });
      }
    });

    this.hubConnection.on('LogOut', () => {
      this.logOut.next({ ...this.logOut.getValue(), status: true });
      this.authActions.next({ method: authMethods.removeTokens });
      this.authActions.next({ method: authMethods.removeUserRole });
    });
  }

  stopHubConnection(): void {
    this.hubConnection.stop();
  }

  forceLogout(): void {
    if (this.hubConnection) {
      this.hubConnection.invoke('ForceLogOut');
    }
  }

  getAuthActions(): Observable<{ method: authMethods }> {
    return this.authActions.asObservable();
  }
}

export function authHubServiceFactory(provider: AuthHubService): () => void {
  return () => provider.connect();
}
