<div class="container modal-container">
  <div class="modal-header">
    <h3 *ngIf="sessionsCount > 0; else oneSession" class="p-0 m-0">
      You have more than one active session. <br />
      If you continue, all sessions will be ended.
    </h3>
    <ng-template #oneSession>
      <h3 class="p-0 m-0">Are you sure you want to log out?</h3>
    </ng-template>
  </div>
  <div>
    <div class="d-flex justify-content-between">
      <button
        class="btn btn-lg confirm-button no-button"
        (click)="bsModalRef.hide()"
      >
        Cancel
      </button>
      <button
        class="btn btn-lg confirm-button yes-button"
        (click)="clickYes()"
      >
        Confirm
      </button>
    </div>
  </div>
</div>
