<mat-card class="info-card">
  <mat-card-header>
    <mat-card-title>{{infoHeading}}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <ng-container *ngFor="let value of values | keyvalue : originalOrder">
      <div class="field"><span class="field-name">{{value.key}}:</span> <span>{{value.value}}</span></div>
    </ng-container>
  </mat-card-content>
</mat-card>
