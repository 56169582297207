import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ErrorService } from './error.service';
import { NotificationsService } from './notifications.service';
import {ErrorMessage} from '@angular/compiler-cli/ngcc/src/execution/cluster/api';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private errorService = this.injector.get(ErrorService);
  private notifier = this.injector.get(NotificationsService);
  message;

  constructor(private injector: Injector) {}

  handleError(error: any) {
    console.error(error.stack.toString());
    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 401: {
          this.message = this.errorService.getClientMessage(error);
          this.notifier.showError(this.message, "Client error");
          break;
        }
        case 409:
        case 400: {
          console.log(error.error);
          break;
        }

        default: {
          this.message = this.errorService.getServerMessage(error);
          this.notifier.showError(this.message, "HTTP Error");
        }
      }
    }
  }
}
