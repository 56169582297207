<div
  *ngIf="isModalShown"
  [config]="{ show: true, backdrop: 'static', keyboard: false }"
  bsModal
  #autoShownModal
  class="modal fade"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-container modal-content">
    <div class="modal-header">
      <h3 class="p-0 m-0 modal-title">Your session has been closed</h3>
      <p class="modal-subtitle">Refresh the page</p>
    </div>
    <div>
      <div class="d-flex justify-content-between">
        <button class="btn-light ok-button" (click)="clickOk()">
          OK
        </button>
      </div>
    </div>
  </div>
</div>
