<div class="d-flex pt-2">

  <div class="d-inline-block m-auto">
    <ul class="pagination justify-content-center" *ngIf="pageData.numberOfPages > 1">
      <li *ngIf="pageData.pageNumber !== initialPage" [ngClass]="'page-item'">
        <span class="page-link" aria-label="First" (click)="pageClicked(initialPage)">
          <span aria-hidden="true">&laquo;</span>
          <span class="sr-only">First</span>
        </span>
      </li>

      <li  [ngClass]="pageData.pageNumber === page ? 'page-item active' : 'page-item'" *ngFor="let page of arrayOfPageNumbers">
        <button (click)="pageClicked(page)" class="page-link" >{{page}}</button>
      </li>

      <li *ngIf="pageData.pageNumber !== pageData.numberOfPages" [ngClass]="'page-item'">
        <span class="page-link" aria-label="Last" (click)="pageClicked(pageData.numberOfPages)">
          <span aria-hidden="true">&raquo;</span>
          <span class="sr-only">Last</span>
        </span>
      </li>
    </ul>
  </div>

  <div class="float-right">Items on page:
    <mat-select class="btn btn-white d-inline-block px-0 py-1 select-per-page-btn" [value]="pageData.itemsPerPage" (selectionChange)="numberOfItemsClick($event.value)" disableOptionCentering>
      <mat-option *ngFor="let amount of itemsAmount" [value]="amount">{{amount}}</mat-option>
    </mat-select>
  </div>

</div>


