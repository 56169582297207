<ng-container *ngIf="authService.isLoggedIn(); else noNavbar">
  <div class="sidebar" data-color="danger">
    <div class="logo d-flex justify-content-start align-items-center">
      <a href="/" class="ml-4 text-white font-weight-bold">Administration</a>
    </div>
    <div class="sidebar-wrapper bg-white">
      <ng-container *ngIf="isMobileMenu()">
        <ul class="nav navbar-nav nav-mobile-menu">
          <li class="nav-item dropdown">
            <a
              class="nav-link"
              href="javascript:void(0)"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="material-icons">person</i>
              <p><span class="d-lg-none d-md-block">Profile</span></p>
            </a>
            <div
              class="dropdown-menu dropdown-menu-right dropped-items"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <ng-container *ngFor="let option of profileOptions">
                <span class="dropdown-item" (click)="optionClick(option.path)">
                  {{ option.title }}
                  <i class="material-icons">{{ option.icon }}</i></span
                >
              </ng-container>
            </div>
          </li>
        </ul>
      </ng-container>

      <ul class="nav">
        <li
          routerLinkActive="active"
          *ngFor="let menuItem of menuItems | restrictAccess"
          class="nav-item">
          <a class="nav-link" [routerLink]="[menuItem.path]">
            <i class="material-icons">{{ menuItem.icon }}</i>
            <p>{{ menuItem.title }}</p>
          </a>
        </li>
      </ul>
    </div>
  </div>
</ng-container>
<ng-template #noNavbar></ng-template>
