import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from './auth.service';
import { ITokensData } from '../interfaces/auth/tokens-data';

@Injectable({ providedIn: 'root' })

export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthService) { }

  addTokenToHeaders(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(this.addTokenToHeaders(req, this.authService.getToken()))
      .pipe(
        catchError(err => {
          if (err instanceof HttpErrorResponse && err.status === 401 && !err.url.includes('admin/refresh')) {
            if (err.url.includes('admin/logout')) {
              this.authService.clientLogout(true);
              return of(null);
            }
            return this.handle401Error(req, next);
          }
          return throwError(err);
        })
      );
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<string | HttpRequest<any> | HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.tokenSubject.next(null);
      return this.authService.refreshTokens()
        .pipe(
          switchMap((newTokens: ITokensData) => {
            if (newTokens.token) {
              this.tokenSubject.next(newTokens.token);
              return next.handle(this.addTokenToHeaders(req, newTokens.token));
            }
            return this.authService.logout();
          }),
          catchError(error => {
            return this.authService.logout();
          }),
          finalize(() => this.isRefreshing = false)
        );
    }

    return this.tokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token: string) => next.handle(this.addTokenToHeaders(req, token))));
  }
}
