import {Component, Input, OnInit} from '@angular/core';
import {KeyValue} from '@angular/common';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.css']
})
export class InfoCardComponent implements OnInit {
  @Input() infoHeading: string;
  @Input() values;

  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

  constructor() {
  }

  ngOnInit(): void {
  }

}
