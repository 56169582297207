import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {IImage} from '../../interfaces/products/image';
import {tap} from 'rxjs/operators';
import {createBlobFromUrl} from '../../utils/blob-from-url';

export const fullImageUrl = `${environment.serverUrl}${environment.adminApiPath}images/`;

@Injectable({providedIn: 'root'})

export class ProductImageService {
  private productsApi = `${environment.adminApiPath}products/`;
  private imagesApi = `${environment.adminApiPath}images/`;
  private primaryImgIdFromPost: number = null;
  noImageApi = 'api/appfiles/noimage.png';

  constructor(private api: HttpClient) {}

  postImage(productId: number, img: IImage): Observable<number> {
    const formData = new FormData();
    const imgBlob = createBlobFromUrl(img.imageSrc);
    formData.append('IsPrimary', img.isPrimary.toString());
    formData.append('Img', imgBlob, img.name);
    return this.api.post<number>(`${this.productsApi}${productId}/images`, formData).pipe(tap(data => {
      if (!this.primaryImgIdFromPost) {
        this.primaryImgIdFromPost = data;
      }
    }));
  }

  putImageAsPrimary(primaryImgId: number): Observable<any> {
    if (!primaryImgId) {
      return this.api.put(`${this.imagesApi}${this.primaryImgIdFromPost}`, {isPrimary: true});
    } else {
      return this.api.put(`${this.imagesApi}${primaryImgId}`, {isPrimary: true});
    }
  }

  deleteImage(imageId: any): Observable<any> {
    return this.api.delete(`${this.imagesApi}${imageId}`);
  }
}
