<ng-container *ngIf="authService.isLoggedIn()">
<nav class="header-bar navbar navbar-expand-lg bg-danger">
  <div class="container-fluid">
    <button mat-raised-button class="navbar-toggler" type="button" (click)="sidebarToggle()">
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navigation">
      <div class="navbar-form">
<!--        <div class="input-group no-border text-white ">-->
<!--          <input class="form-control text-white search-input" [(ngModel)]="searchValue" placeholder="Search"-->
<!--                   (keyup.enter)="startSearch()" aria-label="Search">-->
<!--          <button mat-raised-button type="button" class="btn btn-just-icon border-0 bg-transparent search-btn" (click)="startSearch()">-->
<!--            <i class="material-icons header-icon">search</i>-->
<!--          </button>-->
<!--        </div>-->
      </div>
      <ul class="navbar-nav">
        <li class="nav-item dropdown account-button  ml-2">
          <a class="nav-link  py-1 px-2" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="material-icons header-icon">person_outline</i>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
            <ng-container *ngFor="let option of profileOptions">
              <span class="dropdown-item" (click)=optionClick(option.path)> {{option.title}} <i class="material-icons">{{option.icon}}</i></span>
            </ng-container>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
</ng-container>
