import { BsModalRef } from 'ngx-bootstrap';
import { Component, OnInit } from '@angular/core';
import { AuthHubService } from '../../../services/auth-hub.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-logout-confirmation',
  templateUrl: './logout-confirmation.component.html',
  styleUrls: ['./logout-confirmation.component.css']
})
export class LogoutConfirmationComponent implements OnInit {
  sessionsCount: number;

  constructor(
    public bsModalRef: BsModalRef,
    private authService: AuthService,
    private authHubService: AuthHubService
  ) { }

  ngOnInit() {}

  clickYes() {
    this.authHubService.logOut.next({ thisClient: true, status: false });
    this.authService.logout().subscribe(res => this.bsModalRef.hide());
  }
}
