import {Injectable} from '@angular/core';
import notify from 'devextreme/ui/notify';

@Injectable({
  providedIn: 'root'
})

export class NotificationsService {

  constructor() { }

  showError(message: string, type?: string, displayedTime = 3000): void {
    notify({
        message: `${type.toUpperCase()}: ${message}`,
        width: 600, height: 70, position: 'bottom right'
      },
      'error', displayedTime);
  }
}
