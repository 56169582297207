export enum ENavItem {
  login = 'login',
  home = 'home',
  products = 'products',
  productsKit = 'productsKit',
  orders = 'orders',
  customers = 'customers',
  trips = 'trips-events',
  bonuses = 'bonuses-discounts',
  admin = 'administration',
  adminUsers = 'admin-users',
}
