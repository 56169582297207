import { ERoles } from '../../interfaces/identity/roles-enum';
import {INavItem} from '../../interfaces/shared/nav-items';
import {ENavItem} from '../../interfaces/shared/nav-items-enum';

export const NavItems: INavItem[] = [
  { path: ENavItem.home, title: 'Home',  icon: 'home'},
  { path: ENavItem.products, title: 'Products',  icon: 'dashboard_outlined'},
  { path: ENavItem.productsKit, title: 'Product Kits',  icon: 'dashboard_customize_sharp'},
  { path: ENavItem.orders, title: 'Orders',  icon: 'shopping_cart_outline'},
  { path: ENavItem.customers, title: 'Customers',  icon: 'people_outline'},
  { path: ENavItem.trips, title: 'Trips & Events',  icon: 'event'},
  { path: ENavItem.bonuses, title: 'Bonuses & Discounts',  icon: 'loyalty'},
  { path: ENavItem.adminUsers, title: 'Admin User Settings',  icon: 'settings', protect: [ERoles.SuperAdmin] },
];
