import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SharedModule } from './components/shared/shared.module';
import { TokenInterceptor } from './services/token-interceptor.service';
import { GlobalErrorHandler } from './services/global-error-handler.service';
import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './services/loader-interceptor.service';
import { GlobalDataService, userServiceFactoryForCategories, userServiceFactoryForCountries } from './services/global-data.service';
import { AuthHubService, authHubServiceFactory } from './services/auth-hub.service';
import { RoutingModule } from './routing.module';
import { environment } from '../environments/environment';
// import { AdminsManagementComponent } from './components/admins-management/admins-management.component';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    // AdminsManagementComponent,
  ],
  imports: [
    RoutingModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    NgbModule,
    SharedModule,
    StoreDevtoolsModule.instrument({logOnly: environment.production})
  ],
  providers: [
    GlobalDataService,
    LoaderService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: APP_INITIALIZER, useFactory: userServiceFactoryForCategories, deps: [GlobalDataService], multi: true },
    { provide: APP_INITIALIZER, useFactory: userServiceFactoryForCountries, deps: [GlobalDataService], multi: true },
    { provide: APP_INITIALIZER, useFactory: authHubServiceFactory, deps: [AuthHubService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
