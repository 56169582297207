<div class="photo-element">
  <ng-container *ngIf="!productImages.length; else imagesExist">
    <div class="photos row m-0 p-0 no-pic-photos">
      <div class="col-12 text-light d-flex justify-content-center p-0 main-pic-container m-0">
        <img [src]="productImagesService.noImageApi" alt="main pic" class="pic main-pic single-pic"/>
      </div>
      <i class="float-right">no images</i>
    </div>
  </ng-container>

  <ng-template #imagesExist>
    <ng-container *ngIf="productImages.length > 1 && mainImage; else oneImgBlock">
      <div class="photos row m-0 p-0">
        <div class="col-10 text-light d-flex justify-content-center p-0 main-pic-container">
          <img [src]="fullImageUrl + mainImage.id + '?width=' + imageSizes.medium.width + '&height=' + imageSizes.medium.height"
               (error)="$event.target.src = productImagesService.noImageApi"
               alt="main pic" class="pic main-pic" (click)="onPhotoClick(mainImage)"/>
        </div>
        <div class="col-2 additional-pic-container px-0 m-0">
          <ng-container *ngFor="let p of additionalImages">
            <img [src]="fullImageUrl + p.id + '?width=' + imageSizes.small.width + '&height=' + imageSizes.small.height"
                 (error)="$event.target.src = productImagesService.noImageApi"
                 alt="{{p.id}}" class="pic" (click)="onPhotoClick(p)"/>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-template #oneImgBlock>
      <div *ngIf="mainImage" class="photos row m-0 p-0">
        <div class="col-12 text-light d-flex justify-content-center p-0 main-pic-container m-0">
          <img [src]="fullImageUrl + mainImage.id + '?width=' + imageSizes.medium.width + '&height=' + imageSizes.medium.height"
               (error)="$event.target.src = productImagesService.noImageApi"
               alt="main pic" class="pic main-pic single-pic" (click)="onPhotoClick(mainImage)"/>
        </div>
      </div>
    </ng-template>

  </ng-template>
</div>
