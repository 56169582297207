<div class="wrapper">
  <app-navbar></app-navbar>
  <div [ngClass]="{'main-panel': authService.isLoggedIn(), 'main-panel-mobile': (authService.isLoggedIn() && isMobileMenu())}">
    <app-header></app-header>
    <div class="main-content container-fluid">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<app-spinner></app-spinner>
<app-after-logout-notification></app-after-logout-notification>
