import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { INITIAL_PAGE, ITEMS_PER_PAGE } from '../../../constants/shared/pagination-consts';
import { ADMIN_PROFILE_DROPDOWN_OPTIONS } from '../../../constants/administration/administration-nav-options';
import { ENavItem } from '../../../interfaces/shared/nav-items-enum';
import { LogoutConfirmationComponent } from '../logout-confirmation/logout-confirmation.component';
import { EAdminNavItem } from '../../../interfaces/administration/nav-items-admin-enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  searchValue: string;
  mobile_menu_visible: any = 0;
  toggleButton: any;
  sidebarVisible: boolean;
  profileOptions = ADMIN_PROFILE_DROPDOWN_OPTIONS;
  modalRef: BsModalRef;

  constructor(public authService: AuthService,
    private element: ElementRef,
    private bsModalService: BsModalService,
    private router: Router) {
    this.sidebarVisible = false;
  }

  ngOnInit() {
    this.searchValue = '';
  }

  ngAfterViewInit(): void {
    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    this.router.events.subscribe(() => {
      this.sidebarClose();
      const $layer: any = navbar.getElementsByClassName('close-layer')[0];
      if ($layer) {
        $layer.remove();
        this.mobile_menu_visible = 0;
      }
    });
  }

  optionClick(option: EAdminNavItem) {
    if (option === EAdminNavItem.logOut) {
      this.authService.getActiveSessionsCount()
        .subscribe((data: { quantity: number }) => {
        this.modalRef = this.bsModalService.show(LogoutConfirmationComponent,
          { initialState: { sessionsCount: data.quantity } });
      });
    } else {
      this.router.navigate([ENavItem.admin, option]);
    }
  }

  startSearch() {
    this.router.navigate([`/products/search/`, this.searchValue], {
      queryParams:
        { page: INITIAL_PAGE, perPage: ITEMS_PER_PAGE[1], sort: 0 }
    });
  }

  sidebarToggle() {
    const $toggle = document.getElementsByClassName('navbar-toggler')[0];
    this.sidebarVisible === false ? this.sidebarOpen() : this.sidebarClose();
    const body = document.getElementsByTagName('body')[0];
    const $layer = document.createElement('div');

    if (this.mobile_menu_visible === 1) {
      body.classList.remove('nav-open');

      if ($layer) {
        $layer.remove();
      }
      setTimeout(function () {
        $toggle.classList.remove('toggled');
      }, 400);

      this.mobile_menu_visible = 0;
    } else {
      setTimeout(function () {
        $toggle.classList.add('toggled');
      }, 430);

      $layer.setAttribute('class', 'close-layer');


      if (body.querySelectorAll('.main-panel')) {
        document.getElementsByClassName('main-panel')[0].appendChild($layer);
      } else if (body.classList.contains('off-canvas-sidebar')) {
        document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
      }

      setTimeout(function () {
        $layer.classList.add('visible');
      }, 100);

      $layer.onclick = function () {
        body.classList.remove('nav-open');
        this.mobile_menu_visible = 0;
        $layer.classList.remove('visible');
        setTimeout(function () {
          $layer.remove();
          $toggle.classList.remove('toggled');
        }, 400);
      }.bind(this);

      body.classList.add('nav-open');
      this.mobile_menu_visible = 1;

    }
  }

  sidebarOpen() {
    const body = document.getElementsByTagName('body')[0];
    setTimeout(() => {
      this.toggleButton.classList.add('toggled');
    }, 500);

    body.classList.add('nav-open');

    this.sidebarVisible = true;
  }

  sidebarClose() {
    const body = document.getElementsByTagName('body')[0];
    if (this.toggleButton) {
      this.toggleButton.classList.remove('toggled');
    }
    this.sidebarVisible = false;
    body.classList.remove('nav-open');
  }
}
