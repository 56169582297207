import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {AlertModule, BsModalRef, ModalModule} from 'ngx-bootstrap';
import {
  DxAutocompleteModule,
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxFileUploaderModule,
  DxNumberBoxModule,
  DxSelectBoxModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxValidationGroupModule,
  DxValidatorModule,
} from 'devextreme-angular';

import {PaginationComponent} from './pagination/pagination.component';
import {NavbarComponent} from './navbar/navbar.component';
import {HeaderComponent} from './header/header.component';
import {ProductPhotosComponent} from './product-photos/product-photos.component';
import {ProductPhotoModalComponent} from './product-photos/product-photo-modal/product-photo-modal.component';
import {SpinnerComponent} from './spinner/spinner.component';
import {LogoutConfirmationComponent} from './logout-confirmation/logout-confirmation.component';
import {AfterLogoutNotificationComponent} from './after-logout-notification/after-logout-notification.component';
import {NotEditableComponent} from './not-editable/not-editable.component';
import {RestrictAccessPipe} from './pipes/restrict-access.pipe';
import {GetDateDifferencePipe} from './pipes/get-date-difference.pipe';
import {StaticInfoComponent} from './static-info/static-info.component';
import {ReadMoreComponent} from './read-more/read-more.component';
import {InfoCardComponent} from './info-card/info-card.component';
import {OrderPageComponent} from '../orders/order-page/order-page.component';


const angularModules = [
  RouterModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
];

const materialModules = [
  // devextreme
  DxSelectBoxModule,
  DxDataGridModule,
  DxValidatorModule,
  DxValidationGroupModule,
  DxTextBoxModule,
  DxNumberBoxModule,
  DxDateBoxModule,
  DxCheckBoxModule,
  DxFileUploaderModule,
  DxTextAreaModule,
  DxButtonModule,
  DxAutocompleteModule,

  // Angular materials
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatTooltipModule,
  MatProgressSpinnerModule,
  MatIconModule,
  MatCardModule,
  MatDialogModule
];

const components = [
  PaginationComponent,
  NavbarComponent,
  HeaderComponent,
  ProductPhotosComponent,
  ProductPhotoModalComponent,
  SpinnerComponent,
  LogoutConfirmationComponent,
  AfterLogoutNotificationComponent,
  NotEditableComponent,
  StaticInfoComponent,
  ReadMoreComponent,
  InfoCardComponent,
];

@NgModule({
  imports: [
    ...materialModules,
    ...angularModules,

    // bootstrap
    AlertModule.forRoot(),
    ModalModule.forRoot(),
  ],
  exports: [
    ...angularModules,
    ...materialModules,
    ...components,
    RestrictAccessPipe,
    GetDateDifferencePipe,
  ],
  declarations: [
    ...components,
    RestrictAccessPipe,
    GetDateDifferencePipe,
    StaticInfoComponent,
  ],
  entryComponents: [
    ProductPhotoModalComponent,
    LogoutConfirmationComponent,
    AfterLogoutNotificationComponent,
    NotEditableComponent,
    OrderPageComponent
  ],
  providers: [BsModalRef],
})
export class SharedModule {
}
