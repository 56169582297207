import {createAction, props} from '@ngrx/store';

export enum ProductImageActionsTypes {
  SET_PRIMARY_IMAGE = 'SET_PRIMARY_IMAGE',
  SET_PRIMARY_IMAGE_SUCCESS = 'SET_PRIMARY_IMAGE_SUCCESS',
  SET_PRIMARY_IMAGE_ERROR = 'SET_PRIMARY_IMAGE_ERROR',

  DELETE_IMAGE = 'DELETE_IMAGE',
  DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS',
  DELETE_IMAGE_ERROR = 'DELETE_IMAGE_ERROR',
}


export const deleteImage = createAction(
  ProductImageActionsTypes.DELETE_IMAGE,
  props<{productId: number, fileEntityId: number}>()
);

export const deleteImageSuccess = createAction(
  ProductImageActionsTypes.DELETE_IMAGE_SUCCESS,
  props<{fileEntityId: number}>()
);

export const deleteImageError = createAction(
  ProductImageActionsTypes.DELETE_IMAGE_ERROR
);


export const setPrimaryImage = createAction(
  ProductImageActionsTypes.SET_PRIMARY_IMAGE,
  props<{productId: number, fileEntityId: number}>()
);

export const setPrimaryImageSuccess = createAction(
  ProductImageActionsTypes.SET_PRIMARY_IMAGE_SUCCESS,
  props<{fileEntityId: number, productId: number}>()
);

export const setPrimaryImageError = createAction(
  ProductImageActionsTypes.SET_PRIMARY_IMAGE_ERROR
);
