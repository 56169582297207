<div class="container" [ngClass]="isRow ? 'row' : ''">
  <p *ngIf="name" class="name" [ngClass]="isBiggerPadding ? 'col-4' : 'padding-3'">
    {{ name }}</p>
  <p class="value d-flex align-items-center" [ngClass]="optionalClassForValue" (click)="clickEmitter.emit()">

    <i *ngIf="icon" class="material-icons" [ngClass]="optionalClassForIcon">{{ icon }}</i>

    {{ value }}
  </p>
  <p *ngIf="additionalValue">{{ additionalValue }}</p>
</div>
