import {Component, ElementRef, HostListener, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {OFFSET, MAX_TEXT_LENGTH, DELAY, DEFAULT_ELEM_WIDTH} from './config/read-more.config';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.css'],
})
export class ReadMoreComponent implements OnInit, OnChanges {
  @ViewChild('textWrapper', {static: true}) textWrapper: ElementRef;
  @ViewChild('data', {static: true}) data: ElementRef;
  isCollapsed = false;
  @Input() text = '';
  parentElemWidth = DEFAULT_ELEM_WIDTH;
  childElemWidth = DEFAULT_ELEM_WIDTH;
  showIcons = false;
  hidetext = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.shouldTextCollapse();
  }

  constructor() {
  }

  ngOnInit(): void {
    this.shouldTextCollapse();
  }

  ngOnChanges(): void {
    this.shouldTextCollapse();
  }

  shouldTextCollapse(): void {
    setTimeout(() => {

      this.parentElemWidth = this.textWrapper.nativeElement.offsetWidth + OFFSET;
      this.childElemWidth = this.data.nativeElement.offsetWidth;

      if (this.text.length > MAX_TEXT_LENGTH || this.childElemWidth > this.parentElemWidth) {

        this.isCollapsed = true;
        this.showIcons = true;
        this.hidetext = true;
        return;
      }

      if (this.childElemWidth <= this.parentElemWidth) {
        this.isCollapsed = false;
        this.showIcons = false;
        this.hidetext = false;
        return;
      }
    }, DELAY);
  }

  iconClickToggle(): void {
    this.hidetext = !this.hidetext;
  }
}
