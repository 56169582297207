import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {ADMIN_PROFILE_DROPDOWN_OPTIONS} from '../../../constants/administration/administration-nav-options';
import {NavItems} from '../../../constants/shared/nav-items';
import {INavItem} from '../../../interfaces/shared/nav-items';
import {ENavItem} from '../../../interfaces/shared/nav-items-enum';
import {LogoutConfirmationComponent} from '../logout-confirmation/logout-confirmation.component';
import {EAdminNavItem} from '../../../interfaces/administration/nav-items-admin-enum';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit {
  menuItems: INavItem[] = NavItems;
  searchValue: string;
  profileOptions = ADMIN_PROFILE_DROPDOWN_OPTIONS;
  modalRef: BsModalRef;

  constructor(public authService: AuthService, private router: Router, private bsModalService: BsModalService) {
  }

  ngOnInit() {}

  isMobileMenu() {
    return window.innerWidth < 991;
  }

  optionClick(option: EAdminNavItem) {
    if (option === EAdminNavItem.logOut) {
      this.authService.getActiveSessionsCount()
        .subscribe((data: { quantity: number }) => {
        this.modalRef = this.bsModalService.show(LogoutConfirmationComponent,
          {initialState: {sessionsCount: data.quantity}});
      });
    } else {
      this.router.navigate([ENavItem.admin, 'settings']);
    }
  }
}
