<div #textWrapper class="hide-text__container">
  <div
    [ngClass]="{
      'hide-text__hide': hidetext
    }"
  >
    <span
      #data
      [ngClass]="{
        'hide-text__text': true,
        'hide-text__hide': hidetext,
        'hide-text__visible': !isCollapsed
      }"
      >{{ text }}</span
    >
  </div>

  <!-- show more/less icons -->
  <a (click)="iconClickToggle()" [hidden]="!showIcons" class="hide-text__icon">
    <span class="material-icons" *ngIf="hidetext && showIcons"> expand_more </span>
    <span class="material-icons" *ngIf="!hidetext && showIcons"> expand_less </span>
  </a>
</div>
