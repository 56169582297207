import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ITEMS_PER_PAGE, MAX_BUTTON_NUMBER} from '../../../constants/shared/pagination-consts';
import {IPage} from '../../../interfaces/shared/page';
import {INITIAL_PAGE} from '../../../constants/shared/pagination-consts';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit, OnChanges {
  @Output() pageSelected = new EventEmitter<number>();
  @Output() numberOfItemsOnPageSelected = new EventEmitter<number>();
  @Input() pageData: IPage;
  initialPage: number;
  itemsAmount: number[] = ITEMS_PER_PAGE;
  arrayOfPageNumbers: number[];

  ngOnInit() {
    this.initialPage = INITIAL_PAGE;
    this.createButtonArray();
  }

  ngOnChanges() {
    this.createButtonArray();
  }

  pageClicked(page: number): void {
    if (page !== this.pageData.pageNumber) {
      this.pageSelected.emit(page);
    }
  }

  numberOfItemsClick(itemsNumber: number): void {
    this.numberOfItemsOnPageSelected.emit(itemsNumber);
  }

  createButtonArray() {
    this.arrayOfPageNumbers = [];
    const amountOfPageNumbers = MAX_BUTTON_NUMBER < this.pageData.numberOfPages ?
      MAX_BUTTON_NUMBER : this.pageData.numberOfPages;
    const offset = Math.floor(amountOfPageNumbers / 2);
    let startIndex = this.pageData.pageNumber - offset;

    if (startIndex < this.initialPage) {
      startIndex = this.initialPage;
    }
    if (this.pageData.pageNumber > this.pageData.numberOfPages - offset) {
      startIndex = this.pageData.numberOfPages - amountOfPageNumbers + 1;
    }

    for (let i = 0; i < amountOfPageNumbers; i++) {
      this.arrayOfPageNumbers.push(startIndex + i);
    }
  }
}
