import { ModalDirective } from 'ngx-bootstrap';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthHubService } from '../../../services/auth-hub.service';

@Component({
  selector: 'app-after-logout-notification',
  templateUrl: './after-logout-notification.component.html',
  styleUrls: ['./after-logout-notification.component.css']
})
export class AfterLogoutNotificationComponent implements OnInit {
  @ViewChild('autoShownModal', { static: false }) autoShownModal: ModalDirective;
  isModalShown = false;
  constructor(private authHubService: AuthHubService, private router: Router) { }

  ngOnInit() {
    this.authHubService.logOut.subscribe(logout => {
      if (!logout.thisClient && logout.status) {
        this.showModal();
      }
    });
  }

  showModal(): void {
    this.isModalShown = true;
  }

  clickOk() {
    this.authHubService.stopHubConnection();
    this.isModalShown = false;
    this.router.navigateByUrl('/login');
  }
}
