import {INavItem} from '../../interfaces/shared/nav-items';
import {EAdminNavItem} from '../../interfaces/administration/nav-items-admin-enum';
import { DropdownOptions } from 'src/app/interfaces/administration/admins-list';

export const ADMIN_PROFILE_DROPDOWN_OPTIONS: Array<INavItem & {path: EAdminNavItem}> = [
  { path: EAdminNavItem.productKit, title: 'Product KIT',  icon: ''},
  { path: EAdminNavItem.QA, title: 'Q&A',  icon: ''},
  { path: EAdminNavItem.workspace, title: 'Workspace',  icon: ''},
  { path: EAdminNavItem.watched, title: 'Watched',  icon: ''},
  { path: EAdminNavItem.categories, title: 'Edit categories',  icon: 'edit'},
  { path: EAdminNavItem.settings, title: 'Settings',  icon: 'settings'},
  { path: EAdminNavItem.logOut, title: 'Log out ',  icon: 'exit_to_app'},
];

export const ADMINS_LIST_DROPDOWN_OPTIONS: DropdownOptions[] = [
  { display: 'Active', value: true },
  { display: 'Not active', value: false },
];
