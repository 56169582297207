import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {ProductPhotoModalComponent} from './product-photo-modal/product-photo-modal.component';
import {fullImageUrl, ProductImageService} from '../product-image.service';
import {IImage} from '../../../interfaces/products/image';
import {IMAGE_SIZES} from '../../../constants/products/image-sizes';

@Component({
  selector: 'app-product-photos',
  templateUrl: './product-photos.component.html',
  styleUrls: ['./product-photos.component.css']
})
export class ProductPhotosComponent implements OnInit, OnChanges {
  @Input() productImages: IImage[];
  @Input() productId: number;
  modalRef: BsModalRef;
  mainImage: IImage;
  additionalImages: IImage[];
  fullImageUrl = fullImageUrl;
  imageSizes = IMAGE_SIZES;

  openModal(photo: IImage) {
    const initialState = {
      currentPhoto: photo,
      productId: this.productId,
      photoList: [this.mainImage, ...this.additionalImages],
    };
    this.modalRef = this.bsModalService.show(ProductPhotoModalComponent, {initialState});
    this.modalRef.setClass('mt-5 pt-5 modal-lg modal-very-large');
  }

  constructor(private bsModalService: BsModalService, public productImagesService: ProductImageService) { }
  ngOnInit() { }

  ngOnChanges() {
    this.mainImage = this.productImages.filter(image => image.isPrimary)[0];
    this.additionalImages = this.productImages.filter(image => !image.isPrimary);
  }

  onPhotoClick(photo: IImage) {
    this.openModal(photo);
  }
}
