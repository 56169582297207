import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-static-info',
  templateUrl: './static-info.component.html',
  styleUrls: ['./static-info.component.css'],
})
export class StaticInfoComponent {
  @Input() name: string;
  @Input() value: string;
  @Input() additionalValue: string;
  @Input() optionalClassForIcon?: string;
  @Input() optionalClassForValue?: string;
  @Input() icon?: string;
  @Input() isRow?: boolean;
  @Input() isBiggerPadding?: boolean;
  @Output() clickEmitter = new EventEmitter();

  constructor() {}
}
